var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-modal" },
    [
      _c("vxe-modal", {
        ref: "vxeModal",
        staticClass: "my-modal",
        attrs: {
          width: "20%",
          height: "28%",
          title: _vm.cameraTitle,
          "show-zoom": "",
          resize: "",
          "lock-view": false,
          mask: false,
          "esc-closable": true,
          position: _vm.position,
          transfer: "",
        },
        on: { close: _vm.handleCloaseCamera, hide: _vm.handleCloaseCamera },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "vxe-modal--header-title" }, [
                  _vm._v(_vm._s(_vm.cameraTitle)),
                ]),
                _c("div", { staticClass: "vxe-modal--header-right" }, [
                  _vm.isTianzun
                    ? _c("i", {
                        staticClass: "vxe-modal--right-btn",
                        class: _vm.voiceOn
                          ? "vxe-icon-voice-fill"
                          : "vxe-icon-voice",
                        attrs: {
                          title: _vm.voiceOn ? "语音开启中" : "语音关闭中",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.connectVoice(_vm.voiceOn ? "off" : "on")
                          },
                        },
                      })
                    : _vm._e(),
                  _c("i", {
                    staticClass:
                      "vxe-modal--right-btn vxe-icon-arrow-double-right",
                    attrs: { title: "vxe.modal.right" },
                    on: { click: _vm.toModalRight },
                  }),
                  _c("i", {
                    staticClass: "vxe-modal--zoom-btn trigger--btn",
                    class: _vm.zoomIn ? "vxe-icon-maximize" : "vxe-icon-square",
                    attrs: { title: "vxe.modal.zoomIn" },
                    on: { click: _vm.vxeZoom },
                  }),
                  _c("i", {
                    staticClass:
                      "vxe-modal--close-btn trigger--btn vxe-icon-close",
                    attrs: { title: "vxe.modal.close" },
                    on: { click: _vm.vxeClick },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "a-row",
                  { staticStyle: { height: "72%" } },
                  [
                    _c(
                      "a-col",
                      { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                      [
                        _c("revo-video-player", {
                          attrs: {
                            direction: _vm.direction,
                            videoType: _vm.videoType,
                            rtcInfo: _vm.rtcInfo,
                            vehicleCameraDeviceInfo:
                              _vm.rtcInfo.vehicleCameraDeviceInfos &&
                              _vm.getVehicleCameraDeviceInfo(_vm.mainCameraObj),
                            vehicleId: _vm.info.vehicleId,
                            url: _vm.videoUrl ? _vm.showVedioUrl : "",
                          },
                          on: { handleError: _vm.handleError },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticStyle: { height: "auto" } },
                  [
                    _c(
                      "a-col",
                      { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                      [
                        _c(
                          "a-card",
                          { attrs: { bodyStyle: "padding: 12px" } },
                          _vm._l(_vm.secondaryCameras, function (cam) {
                            return _c(
                              "a-badge",
                              {
                                key: cam.id,
                                staticStyle: {
                                  width: "25%",
                                  cursor: "pointer",
                                },
                              },
                              [
                                !cam.cameraVisible
                                  ? _c("a-icon", {
                                      staticStyle: {
                                        color: "#013a07",
                                        "font-size": "1.5em",
                                      },
                                      attrs: {
                                        slot: "count",
                                        type: "plus-square",
                                      },
                                      slot: "count",
                                    })
                                  : _vm._e(),
                                _c(
                                  "a-card-grid",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      padding: "12px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showCamera(cam.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(cam.cameraName) + " "),
                                    !cam.cameraVisible
                                      ? _c("a-icon", {
                                          attrs: { type: "caret-right" },
                                        })
                                      : _vm._e(),
                                    cam.cameraVisible
                                      ? _c("a-icon", {
                                          attrs: { type: "pause" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.cameraVisible,
          callback: function ($$v) {
            _vm.cameraVisible = $$v
          },
          expression: "cameraVisible",
        },
      }),
      _vm._l(_vm.secondaryCameras, function (cam, index) {
        return _c("revo-video-modal", {
          key: index,
          ref: "rve-" + index,
          refInFor: true,
          attrs: {
            vehicleCameraDeviceInfo:
              _vm.rtcInfo.vehicleCameraDeviceInfos &&
              _vm.getVehicleCameraDeviceInfo(cam),
            cameraInfo: cam,
            videoType: _vm.videoType,
            index: index,
            cameraId: cam.id,
            position: _vm.setCameraPosition(index),
          },
          on: {
            handleError: _vm.handleError,
            closeModalCamera: _vm.closeModalCameraChild,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }