<template>
  <!-- <div :id="info.vehicleId" style="height:100%;"> -->
  <!-- width="35%"
      height="50%" -->
  <div class="video-modal">
    <vxe-modal
      v-model="cameraVisible"
      width="20%"
      ref="vxeModal"
      height="28%"
      class="my-modal"
      :title="cameraTitle"
      @close="handleCloaseCamera"
      @hide="handleCloaseCamera"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :esc-closable="true"
      :position="position"
      transfer
    >
      <template #header>
        <div class="vxe-modal--header-title">{{ cameraTitle }}</div>
        <div class="vxe-modal--header-right">
          <!-- <span class="voice-wrap">
            <img
              v-if="!voiceOn"
              title="语音关闭中"
              class="voice-img"
              @click="connectVoice('on')"
              :src="require('@/assets/images/video/voice-off.png')"
              alt=""
            />
            <img
              v-else
              title="语音开启中"
              class="voice-img"
              @click="connectVoice('off')"
              :src="require('@/assets/images/video/voice-on.png')"
              alt=""
            />
          </span> -->
          <i
            v-if="isTianzun"
            @click="connectVoice(voiceOn ? 'off' : 'on')"
            :title="voiceOn ? '语音开启中' : '语音关闭中'"
            class="vxe-modal--right-btn"
            :class="voiceOn ? 'vxe-icon-voice-fill' : 'vxe-icon-voice'"
          ></i>
          <!-- <i title="vxe.modal.voice" class="vxe-icon-voice-fill" @click="connectVoice"></i> -->
          <i title="vxe.modal.right" class="vxe-modal--right-btn vxe-icon-arrow-double-right" @click="toModalRight"></i>
          <i
            title="vxe.modal.zoomIn"
            @click="vxeZoom"
            :class="zoomIn ? 'vxe-icon-maximize' : 'vxe-icon-square'"
            class="vxe-modal--zoom-btn trigger--btn"
          ></i>
          <i title="vxe.modal.close" @click="vxeClick" class="vxe-modal--close-btn trigger--btn vxe-icon-close"></i>
        </div>
      </template>
      <template #default>
        <a-row style="height: 72%">
          <a-col :span="24" style="height: 100%">
            <revo-video-player
              :direction="direction"
              :videoType="videoType"
              :rtcInfo="rtcInfo"
              :vehicleCameraDeviceInfo="rtcInfo.vehicleCameraDeviceInfos && getVehicleCameraDeviceInfo(mainCameraObj)"
              :vehicleId="info.vehicleId"
              @handleError="handleError"
              :url="videoUrl ? showVedioUrl : ''"
            />
          </a-col>
        </a-row>
        <a-row style="height: auto">
          <a-col :span="24" style="height: 100%">
            <a-card bodyStyle="padding: 12px">
              <a-badge style="width: 25%; cursor: pointer" v-for="cam in secondaryCameras" :key="cam.id">
                <a-icon
                  v-if="!cam.cameraVisible"
                  slot="count"
                  type="plus-square"
                  style="color: #013a07; font-size: 1.5em"
                />
                <a-card-grid @click="showCamera(cam.id)" style="width: 100%; text-align: center; padding: 12px">
                  {{ cam.cameraName }}
                  <a-icon type="caret-right" v-if="!cam.cameraVisible" />
                  <a-icon type="pause" v-if="cam.cameraVisible" />
                </a-card-grid>
              </a-badge>
            </a-card>
          </a-col>
        </a-row>
      </template>
    </vxe-modal>
    <revo-video-modal
      v-for="(cam, index) in secondaryCameras"
      :ref="'rve-' + index"
      :key="index"
      :vehicleCameraDeviceInfo="rtcInfo.vehicleCameraDeviceInfos && getVehicleCameraDeviceInfo(cam)"
      :cameraInfo="cam"
      :videoType="videoType"
      :index="index"
      @handleError="handleError"
      :cameraId="cam.id"
      @closeModalCamera="closeModalCameraChild"
      :position="setCameraPosition(index)"
    />
  </div>
  <!-- </div> -->
</template>

<script>
import get from 'lodash/get'
// import { handleModeData } from '@/translatedata/systemData.js'
import allIcons from '@/core/icons'
import RevoVideoPlayer from './RevoVideoPlayer'
import api from '@/api/iot/vehicle'
// import { setVideo } from '@/api/device/carcontrol'
import RevoVideoModal from './RevoVideoModal'
import { Badge } from 'ant-design-vue'
import MonitorCameraBase from './MonitorCameraBase'
import RtcVoice from './RtcVoice'
import { rtmpRealTimeVideo } from '@/api/iot/cameraDevice'

export default {
  name: 'MonitorMainCameraModal',
  mixins: [MonitorCameraBase, RtcVoice],
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    direction: {
      type: String,
      default: ''
    },
    position: {
      type: Object,
      default: () => {}
    },
    videoType: {
      type: String,
      default: 'rtmp'
    },
    rtcInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    RevoVideoPlayer,
    RevoVideoModal,
    ABadge: Badge
  },
  data() {
    return {
      api,
      cameraIcon: allIcons.cameraIcon,
      // cameraVisible: false,
      timer: null,
      videoUrl: null,
      url: api.videoUrlList[0],
      cameras: [],
      zoomIn: false,
      voiceOn: false,
      rtmpArr: ['testlam01'],
      isTianzun: false
    }
  },
  computed: {
    vehicleId() {
      return get(this.info, 'vehicleId', '')
    },
    mainCameraObj() {
      if (!this.info) {
        return null
      }
      return this.info.cameras.find((c) => c.mainCamera === 'yes')
    },
    cameraTitle() {
      return this.vehicleId + '-实时视频-' + (this.mainCameraObj ? this.mainCameraObj.cameraName : '')
    },
    cameraVisible() {
      if (this.isTianzun) {
        return true
      } else {
        return this.info && this.info.cameras && this.info.cameras.length > 0
      }
    },
    showVedioUrl() {
      if (this.videoUrl.indexOf('http') === 0) {
        return this.videoUrl
      } else {
        return this.url + this.videoUrl
      }
    }
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleCloaseCamera()
      }
    },
    cameraVisible(val) {
      if (val) {
        this.setNewPosition()
      }
    },
    videoType(val) {
      if (val === 'rtc') {
      }
    }
  },
  created() {},
  mounted() {
    this.getFlv('init')
    if (this.videoType === 'rtc') {
    } else {
      if (this.cameraVisible) {
        this.setNewPosition()
      }
    }
  },
  destroyed() {},
  methods: {
    setNewPosition() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.nowPosition) {
            this.nowPosition = this.$refs.vxeModal.getPosition()
            console.log('this.nowPosition 111', this.nowPosition)
          }
        }, 0)
      })
    },
    async getFlv(type) {
      let cameras
      if (this.info && this.info.cameras && this.info.cameras.length === 0) {
        cameras = await this.rtmpRealTimeVideo()
        if (!cameras || cameras.length.length === 0) {
          return
        }
      }
      postData(this.url, api.ffmpegStart, cameras && cameras.length ? cameras : this.info.cameras)
        .then((data) => {
          console.log('视频流地址result:', data)
          if (type === 'init') {
            this.cameras = data.map((d) => {
              d['cameraVisible'] = false
              if (d['mainCamera'] === 'yes') {
                console.log('mainCamera:', d)
                this.videoUrl = d['cameraAddr']
                this.direction = d.id
              }
              return d
            })
          } else {
            for (const item of this.cameras) {
              if (item.id === type) {
                for (const dItem of data) {
                  if (item.id === dItem.id) {
                    if (item['mainCamera'] === 'yes') {
                      this.videoUrl = dItem.cameraAddr
                      this.direction = dItem.id
                    }
                    item.cameraAddr = dItem.cameraAddr
                    break
                  }
                }
              }
            }
          }
          if (this.videoType === 'rtmp') {
            this.showAllCamera()
          }
        }) // JSON from `response.json()` call
        .catch((error) => console.error(error))
      function postData(url, requestPath, data) {
        const requestUrl = url + requestPath
        // Default options are marked with *
        return fetch(requestUrl, {
          body: JSON.stringify(data), // must match 'Content-Type' header
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, same-origin, *omit
          headers: {
            'content-type': 'application/json',
            // 'Authorization': 'Basic YWRtaW46YWRtaW4='
            Authorization: 'Basic YWRtaW46YWRtaW4jMjAyMDEyMTU='
          },
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer' // *client, no-referrer
        }).then((response) => response.json()) // parses response to JSON
      }
    },
    rtmpRealTimeVideo() {
      return new Promise((resolve) => {
        rtmpRealTimeVideo({ vehicleId: this.info.bindVehicleId || this.info.vehicleId }).then((res) => {
          if (res.data.length) {
            this.isTianzun = true
            this.cameraVisible = true
          } else {
            this.isTianzun = false
          }
          resolve(res.data)
        })
      })
    },
    handleCloaseCamera() {
      this.cameraVisible = false
      this.cameras = this.cameras.map((c) => {
        c['cameraVisible'] = false
        return c
      })
      this.$emit('closeModalCamera', this.cameraId)
    },
    handleError(item) {
      this.getFlv(item.direction)
    },
    vxeZoom() {
      // 窗口缩放
      this.$refs.vxeModal.zoom()
      this.zoomIn = !this.$refs.vxeModal.isMaximized()
    },
    vxeClick() {
      this.$refs.vxeModal.close()
    },
    toModalRight() {
      console.log('toModalRight ***', this.nowPosition)
      this.$refs.vxeModal.setPosition(this.nowPosition.top, this.nowPosition.left)
      for (let i = 0; i < this.secondaryCameras.length; i++) {
        this.$refs['rve-' + i][0].toModalRight()
      }
    },
    connectVoice(type) {
      if (type === 'on') {
        this.voiceOn = true
        this.openVoice()
      } else {
        this.voiceOn = false
        this.closeVoice()
      }
    },
    getVehicleCameraDeviceInfo(info) {
      console.log('getVehicleCameraDeviceInfo ****', this.rtcInfo)
      if (this.rtcInfo && this.rtcInfo.vehicleCameraDeviceInfos) {
        for (const item of this.rtcInfo.vehicleCameraDeviceInfos) {
          if (item.cameraType) {
            if (info.cameraAddr.indexOf(item.cameraType) > -1) {
              return item
            }
          } else {
            if (info.cameraName === item.cameraName) {
              return item
            }
          }
        }
      }
      return {}
    }
  }
}
</script>

<style lang="less">
.video-modal {
  .vxe-modal--box {
    min-height: 380px;
  }
  .ant-card {
    height: auto;
    .ant-card-body {
      height: inherit;
      padding: 0px;
      .ant-badge-count,
      .ant-badge-dot,
      .ant-badge .ant-scroll-number-custom-component {
        transform: translate(0%, 0%);
      }
      .ant-card-grid {
        height: inherit;
      }
    }
  }
}
.my-modal {
  .vxe-modal--right-btn {
    cursor: pointer;
    margin-left: 0.6em;
  }
  .voice-wrap {
    position: absolute;
    right: 96px;
    top: 0.5em;
    .voice-tip {
      position: relative;
      top: 0.4em;
      font-size: 12px;
      color: #666;
    }
    .voice-img {
      width: 20px;
      cursor: pointer;
    }
  }
  .vxe-modal--wrapper.type--modal .vxe-modal--header {
    padding: 0.6em 4.6em 0.6em 1em;
  }
}
</style>
